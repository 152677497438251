import React from 'react';
const Footer = ({ arr = [], keyName})=> {

    return (<footer className="main-footer">
    <div className="container">
        <div className="row">
            <div className="col-md-8">
                <ul id="subFooterLinks">
                    {/* <li><a href="#/" target="_blank">With <i className="fa fa-heart pulse"></i> by US</a></li> */}
                    <li><a href="#/return-policy" target="_blank">Politique de remboursement</a></li>
                    <li><a href="https://casamihaiti.com/index.php?route=information/contact" title="Contactez-nous Casami" target="_blank">Contactez-nous</a></li>
                </ul>
            </div>
            <div className="col-md-4">
                <div id="copy">© {new Date().toJSON().substr(0,4)} Casami</div>
            </div>
        </div>
    </div>
</footer>);
}

export default Footer;