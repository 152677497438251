import React from 'react';
const HeaderClient = ({ arr = [], keyName})=> {

    return (<header className="main-header sticky">
			<a href="#menu" className="btn-mobile">
				<div className="hamburger hamburger--spin" id="hamburger">
					<div className="hamburger-box">
						<div className="hamburger-inner"></div>
					</div>
				</div>
			</a>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-6">
						<div id="logo">
							<h1><a href="https://casamihaiti.com/" title="Casami Haïti">CASAMI</a></h1>
						</div>
					</div>
					<div className="col-lg-9 col-6">
						<ul id="menuIcons">
							<li><a href="#/" target="_blank" title="Service client"><i className="icon icon-support"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</header>);
}

export default HeaderClient;