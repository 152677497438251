import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DialogConfirmTrx = ({ openDialog, handleClose }) => {
    React.useEffect(() => {
        // console.log('openDialog--->',openDialog.data)
    },[]);

    return (
        <Dialog
            open={openDialog.isopen}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'sm'}
        >
            <DialogTitle id="alert-dialog-title">
                <strong style={{color: '#006389'}}>PAYMENT RECEIPT</strong>
                <hr />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr style={{borderBottom: '1px solid #006389'}}> 
                                <td style={{float: 'left', color: '#000'}}>{'Amount'}:</td>
                                <td style={{float: 'right'}}>{openDialog.data?.amount || ''}</td>
                            </tr>
                            <tr style={{borderBottom: '1px solid #006389'}}> 
                                <td style={{float: 'left', color: '#000'}}>{'Reference'}:</td>
                                <td style={{float: 'right'}}>{openDialog.data?.transactionID || ''}</td>
                            </tr>
                            <tr style={{borderBottom: '1px solid #006389'}}> 
                                <td style={{float: 'left', color: '#000'}}>{'Message'}:</td>
                                <td style={{float: 'right'}}>{openDialog.data?.message || ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogConfirmTrx;
