import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios-instance';
import useAuth from '../../hooks/useAuth';
import { ReceiptOutlined , PreviewOutlined, CloudSyncOutlined, PaymentOutlined, SyncAltRounded, ScreenSearchDesktopOutlined, SearchRounded} from '@mui/icons-material';
import { date_formater } from '../../utils/contants';
import DialogConfirmTrx from './DialogConfirmTrx';

const MainAdmin = ({handleCloseAlert})=> {
    const { loadingHandler} = useAuth(); 

    const [dataList, setDataList] = useState([{}]);
    const [paginationObj, setPagination] = useState([{}]);
    const [objSearch, setObjSearch] = useState({});

    const loadData = async(query = {})=>{
        // console.log('query--,', query)
        loadingHandler(true);
        try {
            const {sort ='created_at.desc', status = 'PENDING', invoice = '', email = '', gateways_pay, page = 1, pageSize = 5} = query;

            let whereClause = '';
            if(status){ whereClause += `&where[]=status[.]=[.]${status}`}
            if(invoice.length){ whereClause += `&where[]=invoice[.]like[.]%${invoice}%`}
            if(email.length){ whereClause += `&where[]=email[.]like[.]%${email}%`}
            if(gateways_pay){ whereClause += `&where[]=gateways_pay[.]=[.]${gateways_pay}`}
            
            const response = await axiosInstance({method: 'GET', url: `/checkout-requests?${whereClause}`, params: {page: page, pageSize: pageSize, sort: sort}})
            const {result = [], pagination = {}, success = false, server_datetime, timezone} = response.data;
            if(success){ 
                setDataList(result); 
                setPagination(pagination); // { page: 1, pageSize: 20, rowCount: 18, pageCount: 1 }
            }
            /*_currency: "HTG" */
            
            loadingHandler(false);
        } catch (error) {
            loadingHandler(false);
            handleCloseAlert({open:true, message: error.message, severity: 'warning'});
        }
    }

    const handlePaginationSearch = (e, obj = {})=>{
        e.preventDefault();
        loadData(obj);
    }

    const handleUrlgenerator = (item)=>{
        // $envPay = $this->request->get['env'] ?? 'test';
		// 	$transactionId = $this->request->get['transactionId'] ?? '';
		// 	$invoiceID = $this->request->get['invoiceID'] ?? '';
		// 	$idws = $this->request->get['idws'] ?? '';
		// 	$amountdebited = $this->request->get['amountdebited'] ?? 0;
		// 	$currdebited = $typePay == 'MONCASH' ? 'HTG': $this->request->get['currdebited'] ?? 'HTG';
		// 	$resultMessage = $this->request->get['resultMessage'] ?? 'OK';
		// 	$statusSolution = $this->request->get['status'] ?? 'OK';
        //     $keycode = $this->request->get['keycode'] ?? '';
		// 	$baseAPIURL = $this->config->get('payment_credit_card_api') ?? 'http://localhost:3500/api/v1';
        //$baseAPIURL.'/checkout-alert?env='.$envPay.'&gateways_pay='.$typePay.'&invoiceID='.$invoiceID.'&transactionId='.$transactionId.'&status='.$statusSolution.'&_currency='.$currdebited.'&keycode='.$keycode;
        
        
        if(item.gateways_pay){ return `${process.env.REACT_APP_WEBSITE}/index.php?route=checkout/casamipay&invoiceID=${item.invoice}&keycode=${item.keycode}&env=${item.env}&type=${item.gateways_pay}&currdebited=${item._currency}`}
        return '#'
    }

    const handleColor = (status)=>{
        return ['COMPLETED', 'PROCESSED'].includes(status) ? 'GREEN' : 
            ['PENDING'].includes(status) ? 'ORANGE': 'RED';
    }

    const [openDialog, setOpenDialog] = React.useState({isopen: false, data: {}});
    const handleVerifyTrx = async (gateways_pay, {invoice, session_id, keycode})=>{
        loadingHandler(true);
        try {
            if(!['SOLUTIONPAY', 'MONCASH'].includes(gateways_pay)){ throw new Error('Invalid payment type!')}
            const uriPath = gateways_pay === 'SOLUTIONPAY'? `/verify-solution-session?session_id=${session_id}&invoice=${invoice}` : `/verify-moncash-session?orderId=${keycode}`
            
            const response = await axiosInstance({method: 'GET', url: uriPath})
            const {result = {}, pagination = {}, success = false, server_datetime, timezone} = response.data;
            
            if(success){ setOpenDialog({isopen: true, data: result}); }
            loadingHandler(false);
        } catch (error) {
            loadingHandler(false);
            handleCloseAlert({open:true, message: error.message, severity: 'warning'});
        }
    }
    
    const handleChange = (e)=>{
        try {
            e.preventDefault();
            const {name, value} = e.target
            setObjSearch({
                ...objSearch,
                [name]: value
            })
        } catch (error) {}
    }

    useEffect(()=>{
        loadData();
    }, [])

    return (<div className="col-lg-12" id="mainContent">
    <div id="optionGroup1" className="row option-box">
        <div className="ribbon-left"><span className="left"><i className="fas fa-lock me-2"></i>Privée</span></div>
        <div className="option-box-header">
            <h3>Tableau de bord des transactions</h3>
        </div>
    </div>
    
    <div id="personalDetails">
        <div className="row">
            <div className="order-box-header">
                <h3>Liste des transactions [Total: {paginationObj.rowCount}, Page: {paginationObj.page}/{paginationObj.pageCount}]</h3>
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Recherche:</th>
                            {/* sort ='created_at.desc', status = 'PENDING', invoice = '', email = '', gateways_pay */}
                            <th colSpan={2}>
                                <div className="col-12 p-0" style={{border: '1px solid'}}>
                                    <input placeholder={'Invoice'} style={{padding: '5px'}} type="text" className="summaryInput parsley-success" name="invoice" value={objSearch.invoice} onChange={handleChange} />
                                </div>
                            </th>
                            <th colSpan={2}>
                                <div className="col-12 p-0" style={{border: '1px solid'}}>
                                    <input placeholder={'Email'} style={{padding: '5px'}} type="text" className="summaryInput parsley-success" name="email" value={objSearch.email} onChange={handleChange} />
                                </div>
                            </th>
                            <th colSpan={2}>
                                <select id="optionGroup1List" className="wide price-list summaryInput" name="gateways_pay" defaultValue={null} value={objSearch.gateways_pay} onChange={handleChange}>
                                    <option value="" disabled>Gateway</option>
                                    {
                                        ['SOLUTIONPAY', 'MONCASH'].map((item, pos)=> <option key={`tpl-${pos}`} value={`${item}`}>{item}</option>)
                                    }
                                </select>
                            </th>
                            <th colSpan={2}>
                                <select id="optionGroup2List" className="wide price-list summaryInput" name="status" defaultValue={null} value={objSearch.status} onChange={handleChange}>
                                    <option value="" disabled>Status</option>
                                    {
                                        ['CANCELED','COMPLETED','PENDING','PROCESSED','REVERSED','FAILED'].map((item, pos)=> <option key={`tpl-${pos}`} value={`${item}`}>{item}</option>)
                                    }
                                </select>
                            </th>
                            <th>
                                <button className="page-link" href="#" aria-label="Previous" style={{borderRadius: 30}} onClick={(e)=>{ handlePaginationSearch(e, {page: paginationObj.page, ...objSearch})}}>
                                    <SearchRounded />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ajouter le:</th>
                            <th>Invoice</th>
                            <th>Email</th>
                            <th>Qty</th>
                            <th>SYSTEM</th>
                            <th>USD</th>
                            <th>TAUX</th>
                            <th>HTG</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (dataList || []).map((item, position)=>{
                                return <tr key={`iol-${position}`}>
                                    <td><span style={{backgroundColor: handleColor(item.status), borderRadius: 25, width: '100px'}}>&emsp;</span> {item.order_id}</td>
                                    <td>{date_formater(item.created_at || '', true)}</td>
                                    <td>{item.invoice}</td>
                                    <td>{item.email}/{item.phone}</td>
                                    <td>{item.total_items}</td>
                                    <td>{item.gateways_pay}/{item.env}</td>
                                    <td>$ {Number(`${item.total_amount_usd || 0}`).toFixed(2)} USD</td>
                                    <td>{Number(`${item.taux_du_jour || 0}`).toFixed(2)} HTG</td>
                                    <td>{Number(`${item.total_amount_htg || 0}`).toFixed(2)} HTG</td>
                                    <td>
                                        <ReceiptOutlined onClick={(e)=>{
                                            e.preventDefault();
                                            handleVerifyTrx(item.gateways_pay, {invoice: item.invoice, session_id: item.session_id, keycode: item.keycode});
                                        }} />
                                        <a href={handleUrlgenerator(item)} target='_blank' rel={'noreferrer'}><PreviewOutlined /></a>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={10}>
                                <div className="row justify-content-center">
                                    <nav aria-label="Table Pagination">
                                        <ul className="pagination">
                                            <li className="page-item" id="prev-page">
                                                <button className="page-link" href="#" aria-label="Previous" disabled={paginationObj.page == 1 ? true: false} onClick={(e)=>{ handlePaginationSearch(e, {page: paginationObj.page - 1});}}>
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            <li className="page-item" id="prev-page">
                                                <button className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">{paginationObj.page}</span>
                                                </button>
                                            </li>
                                            <li className="page-item" id="next-page">
                                                <button className="page-link" href="#" aria-label="Next" onClick={(e)=>{ handlePaginationSearch(e, {page: paginationObj.page + 1});}}>
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>  
            {
                openDialog.isopen ? 
                <DialogConfirmTrx openDialog={openDialog} handleClose={(e)=>{ e.preventDefault(); setOpenDialog({isopen: !openDialog.isopen, data: {}}); }} /> : <></>
            }   
        </div>
    </div>
</div>);
}

export default MainAdmin;