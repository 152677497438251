import axios from "axios";
const axiosInstance = axios.create(
  process.env.NODE_ENV === 'production' ?
  {
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
  }:{});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error?.response?.data || "Something went wrong!")
);

export default axiosInstance;
