import React from 'react';
import { Link } from 'react-router-dom';
const HeaderAdmin = ({})=> {

    return (<div className="col-lg-12" id="mainContent">
        <div id="optionGroup13" className="row option-box">
            {/* <div className="ribbon-left"><span className="left"><i className="fas fa-lock me-2"></i>Privée</span></div> */}
            <div className="option-box-header">
                <ul id="subFooterLinks" className='contact-links'>
                    <li><Link to={"/admin/dashboard"} >Dashboard</Link></li>
                    <li><Link to={"/admin/taux-du-jour"} title="Taux du Jour">Taux du Jour</Link></li>
                </ul>
            </div>
        </div>
    </div>);
}

export default HeaderAdmin;