export const BASE_URL = 'https://pay.casamihaiti.com/api';
export const WEBSITE_URL = 'https://casamihaiti.com/index.php?';
export const STAGING_WEBSITE_URL = 'https://staging.casamihaiti.com/index.php?';

export const date_formater = (str_date = '', secondFormat = false)=>{
    const month = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ]
    
    const dateSpliter = str_date.substring(0,10).split('-');
    const currentMonth = Number(dateSpliter[1] || 1)
    if(!secondFormat){ return `${dateSpliter[2] || ''}.${dateSpliter[1] || ''}.${dateSpliter[0] || ''}`;}
    return `${month[currentMonth-1]}, ${dateSpliter[2] || ''}/${dateSpliter[0] || ''}`;
} 
