// import { MatxLoading } from "app/components";
import axiosInstance from "../axios-instance";
import {jwtDecode} from 'jwt-decode';
import { createContext, useEffect, useReducer } from "react";
import Preloader from "../pages/children/Preloader";


const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  isLoading: false,
  user: null, 
  lang: 'fr'
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (arrSession = []) => {

  arrSession.forEach(item=>{
    if(item.type === 'remove'){
      localStorage.removeItem(`${item.key}`);
      if(item.key === 'casami_accessToken'){  delete axiosInstance.defaults.headers.common.Authorization; }
    }

    if (item.type === 'add') {
      localStorage.setItem(`${item.key}`, item.value);
      if(item.key === 'casami_accessToken'){  axiosInstance.defaults.headers.common.Authorization = `Bearer ${item.value}`; }
    }
  })
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isLoading: false,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }

    case "SET_LANGUAGE": {
      const { lang } = action.payload;
      return {
        ...state,
        lang: lang,
      };
    }

    case "LOADING": {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading: isLoading,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  loadingHandler: () => Promise.resolve(),
  signInWithGoogleCb: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleLanguage = async (ln = 'fr') => {
    try {
      setSession([{key: 'lang', value: ln, type: 'add'}]);
      dispatch({
        type: "SET_LANGUAGE",
        payload: {
          lang: ln
        },
      });
    } catch (error) {
      dispatch({
        type: "SET_LANGUAGE",
        payload: {
          lang: 'fr'
        },
      });
    }
  }

  const handleSessionUser = (type, accessToken, data)=>{
    try {
      const lang = window.localStorage.getItem("lang") || 'fr'; // type: 'remove' ||'ADD'
      setSession([{key: 'lang', value: lang, type: 'add'}, {key: 'casami_accessToken', value: accessToken, type: 'add'}]);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      dispatch({ type: type, payload: data });

    } catch (error) {
      
    }
  }

  const login = async (email, password) => {
    try {

        const response = await axiosInstance.post(`/signin`, {
            email,
            password,
        });
        const {result, success, error} = response.data;
        console.log('result', result, success, error)
        handleSessionUser('LOGIN', result.token, result);
        return {success: true, message: 'Connecter avec succès', code: 'SUC001'}
    } catch (error) {
      return {success: false, message: error.message, code: 'ERR001'};
    }
  };

  const resetPassword = async (email, password, _token) => {
    try {
      const response = await axiosInstance.post(`/signin/resetpassword`, {
        email,
        password,
        token: _token
      });

      const {result, success, error} = response.data;

      return {success: true, message: 'Reinitialiser avec succès', code: 'SUC001'}
    } catch (error) {
      return {success: false, message: error.message, code: 'ERR001'};
    }
  };

  const authValidity = async () => {
    const lang = window.localStorage.getItem("lang") || 'fr';
    try {
      const accessToken = window.localStorage.getItem("casami_accessToken");
      
      if (accessToken && isValidToken(accessToken)) {
        setSession([{key: 'lang', value: lang, type: 'add'}]);
        // const response = await axios.get("/api/auth/profile");
        // const { user } = response.data;

        const response = await axiosInstance.get(`/auth`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const {result, success, error} = response.data;
        if(!success){ throw new Error('User not login yet, please enter your email and password')}

        handleSessionUser('INIT', accessToken, {
          isAuthenticated: true,
          user: {...result},
          lang: lang
        });
  
        return {success: true, message: 'Connecter avec succès'}
      } else {
        setSession([{key: 'casami_accessToken', value: '', type: 'remove'}]);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
            lang: lang
          },
        });
        throw new Error('User not login yet, please enter your email and password')
      }
    } catch (err) {
      
      dispatch({
        type: "INIT",
        payload: {
          isAuthenticated: false,
          user: null,
          lang: lang
        },
      });
      return {success: false, message: 'User not login yet, please enter your email and password'}
    }
  }

  const logout = () => {
    const lang = window.localStorage.getItem("lang") || 'fr';
    setSession([{key: 'lang', value: lang, type: 'add'}, {key: 'casami_accessToken', value: null, type: 'remove'}]);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer `;
    dispatch({ type: "LOGOUT" });
  };

  const loadingHandler = (value = false) => {
    dispatch({ type: "LOADING", payload: {isLoading: value}});
  };

  useEffect(() => {
    authValidity();
  }, []);

  if (!state.isInitialised) { return <></>; }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        authValidity,
        handleLanguage,
        resetPassword,
        loadingHandler
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
