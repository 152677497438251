import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios-instance';
import useAuth from '../../hooks/useAuth';
import { ReceiptOutlined , PreviewOutlined, CloudSyncOutlined, PaymentOutlined, SyncAltRounded, ScreenSearchDesktopOutlined, SearchRounded} from '@mui/icons-material';
import { date_formater } from '../../utils/contants';

const MainTaux = ({dataList = []})=> {
    useEffect(()=>{  }, [])

    return (<div className="col-lg-8" id="mainContent">
    <div id="personalDetails">
        <div className="row">
            <div className="order-box-header">
                <h3>Nos derniers taux</h3>
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Devise</th>
                            <th>Taux Carte</th>
                            <th>Taux Moncash</th>
                            <th>Ajoutée le</th>
                            <th>Ajoutée Par</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (dataList || []).map((item,position)=>{
                                return <tr key={`tdj-${position}`}>
                                    
                                    <td>{position === 0 ? <span style={{backgroundColor: 'green', borderRadius: 25, width: '100px'}}>&emsp;</span>: <>#{item.id}</>}</td>
                                    <td>{item._currency}</td>
                                    <td>{Number(`${item.value_bank || 0}`).toFixed(2)} HTG</td>
                                    <td>{Number(`${item.value_moncash || 0}`).toFixed(2)} HTG</td>
                                    <td>{date_formater(item.created_at || '', true)}</td>
                                    <td>{item.user_name}</td>
                                    <td></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>     
        </div>
    </div>
</div>);
}

export default MainTaux;