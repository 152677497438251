import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = ({handleClose = ()=>{}, alertSnap})=> {
    return (<Snackbar open={alertSnap.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertSnap.severity || "success"} sx={{ width: '100%' }}>
        {alertSnap.message || ''}
      </Alert>
    </Snackbar>);
}

export default AlertSnackbar;