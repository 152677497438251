import React from 'react';
import { BASE_URL, date_formater } from '../../utils/contants';
import { Button, TextField } from '@mui/material';
import Axios from 'axios';

const NewExchangeRate = ({handleSubmit})=> {
    const [dataInsert, setDataInsert] = React.useState({value_bank: 0, value_moncash: 0, _currency: 'USD'});
    const handleChange = (e)=>{
      e.preventDefault();
      setDataInsert({
        ...dataInsert,
        [e.target.name]: e.target.value
      })
    }

    return (<div className="col-lg-4" id="sidebar">
        <div id="orderContainer" className="theiaStickySidebar">
            <div className="row">
                <div className="col-md-12">
                    <h3>@Taux du jour</h3>
                    <div className="row sub-total-container">
                        <div className="col-6 p-0">
                            <span className="summaryInput">Taux Banque:</span>
                        </div>
                        <div className="col-6 p-0" style={{border: '1px solid red'}}>
                            <input style={{padding: '5px'}} type="number" className="summaryInput parsley-success" name="value_bank" value={dataInsert.value_bank} onChange={handleChange} placeholder='0.00' />
                        </div>
                    </div>
                    <div className="row sub-total-container">
                        <div className="col-6 p-0">
                            <span className="summaryInput">Taux moncash:</span>
                        </div>
                        <div className="col-6 p-0" style={{border: '1px solid red'}}>
                            <input style={{padding: '5px'}} type="number" className="summaryInput parsley-success" name="value_moncash" value={dataInsert.value_moncash} onChange={handleChange} placeholder='0.00' />
                        </div>
                    </div>
                    <div className="buttons"> <button onClick={(e)=>{ handleSubmit(e, dataInsert); }} className="btn btn-block" style={{backgroundColor: '#182F72', color: '#fff'}}>Enregistrer</button> </div>
                </div>
            </div>
        </div>
    </div>);
}

export default NewExchangeRate;