import React, { useEffect, useState } from 'react';
import Preloader from './children/Preloader';
import HeaderClient from './children/HeaderClient';
import SubHeader from './children/SubHeader';
import Footer from './children/Footer';
import NewExchangeRate from './children/NewExchangeRate';
import MainAdmin from './children/MainAdmin';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import AlertSnackbar from './children/AlertSnackbar';
import HeaderAdmin from './children/HeaderAdmin';
import MainTaux from './children/MainTaux';
import axiosInstance from '../axios-instance';
import useAuth from '../hooks/useAuth';

const TauxDujourInterface = ()=>{
    const [alertSnap, setAlertSnap] = useState({open: false, message: '', severity: 'warning'}) 
    const { loadingHandler, user} = useAuth(); 
    const handleCloseAlert = (obj)=>{ 
      const {open= false, message= '', severity ='warning'} =  obj || {};
      setAlertSnap({...alertSnap, open: open || false, message: message || '', severity: severity || 'warning'}); 
    };

    const [dataList, setDataList] = useState([{}]);

    const loadData = async(query = {})=>{
        loadingHandler(true);
        try {
            const {sort ='created_at.desc', page = 1, pageSize = 20} = query;

            const response = await axiosInstance({method: 'GET', url: `/exchange-rate-sys`, params: {page: page, pageSize: pageSize, sort: sort}})
            const {result = [], success = false, server_datetime, timezone} = response.data;
            if(success) setDataList(result); 
            
            loadingHandler(false);
        } catch (error) {
            loadingHandler(false);
            handleCloseAlert({open:true, message: error.message, severity: 'warning'});
        }
    }

    const handleSubmit = async (e, data)=>{
        e.preventDefault();
        loadingHandler(true);
        try {
            const response = await axiosInstance({
                method: 'POST',
                url: `/exchange-rate-sys`,
                data: {...data, user_name: user?.user?.username || '', _currency: 'USD'}
            })

            const {result = {}, success = false, server_datetime, timezone} = response.data;
            handleCloseAlert({open: true, message: 'save successfully', severity: 'success'});
            loadingHandler(false);
            loadData();
        } catch (error) {
            handleCloseAlert({open: true, message: error.message, severity: 'warning'});
            loadingHandler(false);
        }
      }

    useEffect(()=>{
        loadData();
    }, [])
      
    return (<div className="row">
        <HeaderAdmin />
        <NewExchangeRate handleSubmit={handleSubmit} />
        <MainTaux dataList={dataList} />
        <AlertSnackbar open={true} alertSnap={alertSnap} handleClose={handleCloseAlert} />
    </div>)
}

export default TauxDujourInterface;