import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { lazy } from "react";
import { AuthProvider } from './contexts/JWTAuthContext';
import AuthGuard from './auth/AuthGuard';
import TauxDujourInterface from './pages/TauxDujourInterface';

const NewShoppingCart = lazy(() => import("./pages/NewShoppingCart"));
const AdminDashboard = lazy(() => import("./pages/AdminInterface"));
const LoginInterface = lazy(() => import("./pages/LoginInterface"));
const DesignLayout = lazy(() => import("./layouts/DesignLayout"));
const NotFound = lazy(() => import("./pages/NotFound"));
 //
const router = createBrowserRouter([
  {
    path: "/checkout",
    element: <NewShoppingCart />,
    errorElement: <>UNEXPECTED ERROR, Please reload</>
  },
  {
    path: "/admin",
    element: <AuthProvider><DesignLayout /></AuthProvider>,
    errorElement: <>UNEXPECTED ERROR, Please reload</>,
    children: [
      {
        path: "",
        element: <AuthGuard></AuthGuard>,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
      {
        path: "login",
        element: <LoginInterface />,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
      {
        path: "dashboard",
        element:  <AuthGuard><AdminDashboard /></AuthGuard>,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
      {
        path: "taux-du-jour",
        element:  <AuthGuard><TauxDujourInterface /></AuthGuard>,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
      {
        path: "details",
        element: <>DETAILS</>,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
      {
        path: "*",
        element: <AuthGuard></AuthGuard>,
        errorElement: <>UNEXPECTED ERROR, Please reload</>
      },
    ]
  },
  {
    path: "/*",
    element: <NotFound />,
    errorElement: <>UNEXPECTED ERROR, Please reload</>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
