import useAuth from "./../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  return (
    <>
      {
        isAuthenticated ? 
        children : 
        <Navigate replace to="/admin/login" state={{ from: pathname }} />
      }
    </>
  );
};

export default AuthGuard;
