import React from 'react';
const SubHeader = ({ arr = [], keyName})=> {

    return (<div className="sub-header">
    <div className="container">
        <h1>{''}</h1>
    </div>
</div>);
}

export default SubHeader;